let globalError = 'There was a problem on our side. Please try again later.';
if (window.navigator.language.substr(0,2) == 'ru') globalError = 'Обнаружена проблема на нашей стороне. Попробуйте повторить запрос позднее.';

let host = 'https://api.ezbooks.ru';
const full_host = window.location.hostname;

const loc = window.location.host.split('.');
if ((typeof loc[0] != 'undefined' && loc[0] == 'localhost:8080') || (typeof loc[1] != 'undefined' && loc[1] == 'brooklynwebsite')){
    host = 'https://bookin.brooklynwebsite.com';
}
const api_url =  host + '/api/v1';

import store from "../index";

const get = async (url) => {
    try{
        return await fetch( url )
            .then((response) => response.json() )
            .then((data) => {
                if (data.status != 'ok'){
                    store.dispatch('setError', data.message);
                    return false;
                }
                else{
                    store.dispatch('setError', null);
                    return data;
                }
            })
            .catch(() => {
                store.dispatch('setError', globalError);
                return false;
            });
    }
    catch(error){
        store.dispatch('setError', globalError);
        return false;
    }
}

const post = async (url, payload) => {
    const requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(payload)
    };
    try{
        return await fetch( url, requestOptions )
            .then((response) => response.json() )
            .then((data) => {
                if (data.status != 'ok'){
                    store.dispatch('setError', data.message);
                    return false;
                }
                else{
                    store.dispatch('setError', null);
                    return data;
                }
            })
            .catch(() => {
                store.dispatch('setError', globalError);
                return false;
            });
    }
    catch(error){
        store.dispatch('setError', globalError);
        return false;
    }
}

export default {
    state: {

    },
    mutations: {

    },
    actions: {
        async getInitApp ({}) {
            return await get( api_url + '/init/?host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone ).then((ret) => {
                return ret;
            });
        },
        async getEvents ({}, payload) {
            return await get( api_url + '/booking_types/?location=' + payload + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone ).then((ret) => {
                return ret;
            });
        },
        async getMainCalendar ({}, payload) {
            const participants = store.getters.getParticipants;
            return await post( api_url + '/schedule/get_calendar_by_event.php?event_id=' + payload.eventId + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { participants: participants } ).then((ret) => {
                return ret;
            });
        },
        async getCalendarItem ({}, payload) {
            const participants = store.getters.getParticipants;
            return await post( api_url + '/schedule/get_calendar_by_event.php?event_id=' + payload.eventId + '&month=' + payload.month + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { participants: participants } ).then((ret) => {
                return ret;
            });
        },
        async getCalendarItemByLocation ({}, payload) {
            const participants = store.getters.getParticipants;
            return await post( api_url + '/schedule/get_calendar_by_event.php?event_id=' + payload.eventId + '&month=' + payload.month + '&location_id=' + payload.locationId + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { participants: participants } ).then((ret) => {
                return ret;
            });
        },
        async getTimeslots ({}, payload) {
            const dates = store.getters.getSelectedDates.filter((i) => i != null);
            const participants = store.getters.getParticipants;

            let url = api_url + '/schedule/get_timeslots_by_date.php?event_id=' + payload.eventId;
            if (typeof payload.locationId != 'undefined'){
                url += '&location_id=' + payload.locationId;
            }
            url += '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone;

            return await post( url, { dates: dates, participants: participants } ).then((ret) => {
                return ret;
            });
        },
        async getMapPoints ({}, payload) {
            const participants = store.getters.getParticipants;
            return await post( api_url + '/schedule/get_locations_by_event.php?event_id=' + payload.eventId + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { participants: participants } ).then((ret) => {
                return ret;
            });
        },
        async getLocationCalendar ({}, payload) {
            const participants = store.getters.getParticipants;
            return await post( api_url + '/schedule/get_calendar_by_event.php?event_id=' + payload.eventId + '&location_id=' + payload.locationId + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { participants: participants } ).then((ret) => {
                return ret;
            });
        },
        async getLocationTimeslots ({}, payload) {
            const dates = store.getters.getSelectedDates.filter((i) => i != null);
            const participants = store.getters.getParticipants;
            return await post( api_url + '/schedule/get_timeslots_by_date.php?event_id=' + payload.eventId + '&location_id=' + payload.locationId + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { dates: dates, participants: participants } ).then((ret) => {
                return ret;
            });
        },
        async getPricingDetailsFromServer ({}, payload) {
            const participants = store.getters.getParticipants;
            const additionalServices = store.getters.getAdditionalServices;
            return await post( api_url + '/schedule/get_pricing_details_by_timeslot.php?timeslot_id=' + payload + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { participants: participants, additional: additionalServices } ).then((ret) => {
                return ret;
            });
        },
        async setCreateOrder ({}, payload) {
            const participants = store.getters.getParticipants;
            const additionalServices = store.getters.getAdditionalServices;
            const utm = store.getters.getUTM;


            return await post( api_url + '/orders/create_order.php?timeslot_id=' + payload.timeslot + '&host=' + full_host + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, { participants: participants, additional: additionalServices, customer: payload.credentials, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, utm: utm } ).then((ret) => {
                return ret;
            });
        },
    },
    getters: {

    }
}
