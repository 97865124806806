import { createRouter, createWebHistory } from 'vue-router'
import store from "../store/index";

import authGuard            from "./guards/authGuard";
import locationGuard        from "./guards/locationGuard";
import catalogGuard         from "./guards/catalogGuard";
import eventGuard           from "./guards/eventGuard";

import loadCalendar         from "./loaders/loadCalendar";
import loadMap              from "./loaders/loadMap";

const notFound              = () => import(/* webpackChunkName: "main" */ '../views/notFound.vue');

const chooseLocation        = () => import(/* webpackChunkName: "main" */ '../views/main_00.vue');
const eventsCatalog         = () => import(/* webpackChunkName: "main" */ '../views/main_01.vue');
const chooseEvents          = () => import(/* webpackChunkName: "main" */ '../views/main_02.vue');
const viewEvent             = () => import(/* webpackChunkName: "main" */ '../views/main_03.vue');

const viewCalendar          = () => import(/* webpackChunkName: "main" */ '../views/main_calendar.vue');
const viewMap               = () => import(/* webpackChunkName: "main" */ '../views/main_map.vue');
const viewMapYdx               = () => import(/* webpackChunkName: "main" */ '../views/main_map_ydx.vue');


const routes = [
    // {
    //     path: '/',
    //     name: 'chooseLocation',
    //     component: chooseLocation,
    //     beforeEnter: authGuard
    // },
    {
        path: '/',
        name: 'eventsCatalog',
        component: eventsCatalog,
        beforeEnter: [authGuard],
        props: true
    },
    {
        path: '/:catalogId',
        name: 'chooseEvents',
        component: chooseEvents,
        beforeEnter: [authGuard, catalogGuard]
    },
    {
        path: '/:catalogId/:eventId',
        name: 'viewEvent',
        component: viewEvent,
        beforeEnter: [authGuard, catalogGuard, eventGuard]
    },
    {
        path: '/:catalogId/:eventId/reserve',
        name: 'viewCalendar',
        component: viewCalendar,
        beforeEnter: [authGuard, catalogGuard, eventGuard, loadCalendar]
    },
    {
        path: '/:catalogId/:eventId/map2',
        name: 'viewMap',
        component: viewMap,
        beforeEnter: [authGuard, catalogGuard, eventGuard, loadMap]
    },
    {
        path: '/:catalogId/:eventId/map',
        name: 'viewMapYdx',
        component: viewMapYdx,
        beforeEnter: [authGuard, catalogGuard, eventGuard, loadMap]
    },


    {
        path: '/404',
        name: "404 Page",
        component: notFound,
        beforeEnter: authGuard
    },
    {
        path: '/:pathMatch(.*)*',
        name: "Default Page",
        redirect: '/404'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { left: 0, top: 0 };
    }
});

export default router
