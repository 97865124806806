import store from '../../store/index'
import router from '../index'

export default function (to, from, next) {
    if (store.getters.getAuth) {
        store.dispatch('setLoading', false);
        next();
    } else {
        store.dispatch('setLoading', true);
        store.dispatch('getInitApp').then((ret) => {
            if (store.getters.getError == null){
                store.dispatch('setAuth', ret.data);
                store.dispatch('setLocations', ret.data);
                document.querySelector('head title').innerHTML = ret.data.app_settings.meta_title;

                if (typeof ret.data.app_settings.header_script_injection != 'undefined' && ret.data.app_settings.header_script_injection != ''){
                    const header_script = document.createElement('script');
                    header_script.type = 'text/javascript';
                    header_script.innerHTML = ret.data.app_settings.header_script_injection;
                    document.querySelector('head').appendChild(header_script);
                }

                if (typeof ret.data.app_settings.footer_script_injection != 'undefined' && ret.data.app_settings.footer_script_injection != ''){
                    const footer_script = document.createElement('script');
                    footer_script.type = 'text/javascript';
                    footer_script.innerHTML = ret.data.app_settings.footer_script_injection;
                    document.querySelector('body').appendChild(footer_script);
                }

                if (typeof ret.data.app_settings.body_html_injection != 'undefined' && ret.data.app_settings.body_html_injection != ''){
                    window.document.body.insertAdjacentHTML( 'afterbegin', ret.data.app_settings.body_html_injection );
                }

                if(Object.keys(to.query).length !== 0) {
                    localStorage.setItem('utm', btoa(JSON.stringify(to.query)));
                    localStorage.setItem('utmTime', new Date().getTime());
                    store.dispatch('setUTM', btoa(JSON.stringify(to.query)));
                }
                else if (localStorage.getItem('utm') != null && localStorage.getItem('utmTime') != null && +localStorage.getItem('utmTime') > (new Date().getTime() - 1000*60*20)) {
                    localStorage.setItem('utmTime', new Date().getTime());
                    store.dispatch('setUTM', localStorage.getItem('utm'));
                }

                store.dispatch('getEvents').then((ret) => {
                    if (store.getters.getError == null){
                        store.dispatch('setEvents', {catalog: ret.data.catalog, location: location});
                        store.dispatch('setLoading', false);
                        next();
                    }
                });
            }
        });
    }
}
