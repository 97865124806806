import store from '../../store/index'

export default function (to, from, next) {
	const item = store.getters.getCatalog.find((i) => i.id == to.params.catalogId);
    if ( typeof item != 'undefined' ){
		  next();
    }
    else{
        next('/404');
    }
}