<template>
	<span v-html="size == 'big' ? appSettings.logo_w_big : appSettings.logo_w_small"></span>
</template>

<script>
	export default {
		props: {
			size: String
		},
		computed: {
			appSettings () {
				return this.$store.getters.getAppSettings;
			},
		}
	};
</script>